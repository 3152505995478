import { PluginListenerHandle, registerPlugin } from '@capacitor/core';

/** Incoming call information. */
export type AcceptCallPayload = Readonly<{

	/** Consultation id. */
	consultationId: string;

	/** Whether incoming call is a video call. */
	isVideoCall: boolean;

	/** Caller display name. */
	callerDisplayName: string;
}>;

/** Start call data. */
export type StartCallData = Readonly<{

	/** Name. */
	name: string;

	/** Flag to define video or audio call. */
	isVideoCall: boolean;

	/** Consultation. */
	consultation: string;
}>;

type CallForegroundPlugin = Readonly<{

	/** Start call. */
	startCall(data: StartCallData): Promise<void>;

	/** Stop call. */
	stopCall(): Promise<void>;

	/**
	 * Register listener for a use case when user accepts incoming call.
	 * @param eventName Event name.
	 * @param listenerFunc Callback function.
	 */
	addListener(
		eventName: 'acceptCall',
		listenerFunc: (data: AcceptCallPayload) => void,
	): Promise<PluginListenerHandle> & PluginListenerHandle;

	/**
	 * Register listener for a use case when user hangs up the call.
	 * @param eventName Event name.
	 * @param listenerFunc Callback function.
	 */
	addListener(
		eventName: 'hangUpCall',
		listenerFunc: () => void,
	): Promise<PluginListenerHandle> & PluginListenerHandle;

	/**
	 * Register listener for a use case when user wants to return back to active call.
	 * @param eventName Event name.
	 * @param listenerFunc Callback function.
	 */
	addListener(
		// eslint-disable-next-line @typescript-eslint/unified-signatures
		eventName: 'openActiveCall',
		listenerFunc: () => void,
	): Promise<PluginListenerHandle> & PluginListenerHandle;
}>;

/**  */
export const CallForegroundPluginName = 'VonageCap';

/** Provide functionality to keep calls working when app is in background or device is locked. */
// eslint-disable-next-line @typescript-eslint/naming-convention -- Plugins are usually named with PascalCase.
export const CallForeground = registerPlugin<CallForegroundPlugin>(CallForegroundPluginName);
